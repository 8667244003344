import IKitchen from "../interfaces/kitchen";

export default class Kitchen {

    private _kitchen: IKitchen;
    private _id: string;
    constructor(id: string, kitchen: IKitchen) {
        this._kitchen = kitchen
        this._id = id;
    }
    public get name() {
        return this._kitchen.name;
    }
    public get id() {
        return this._id;
    }
}