import IUSer from "../interfaces/user";
import Kitchen from "./kitchen";

export default class User {
    private _user: IUSer;
    private _favourites?: Kitchen[];

    constructor(user: IUSer) {
        this._user = user;
    }
    public get id() {
        return this._user.id;
    }
    public get name() {
        return this._user.name;
    }
    public get favourites(): Kitchen[] | null {
        if (!this._favourites) return null;
        return [...this._favourites];
    }
    public get primaryKitchen(): string {
        return this._user.primary_kitchen || "";
    }
    public set primaryKitchen(id: string) {
        this._user.primary_kitchen = id;
    }
    public set favourites(favs: Kitchen[] | null) {
        if (!favs) return;
        this._favourites = favs
    }
    public addFav(kitchen: Kitchen) {
        if (this._favourites)
            this._favourites.push(kitchen);
    }
    public removeFav(kitchen: Kitchen) {
        if (this._favourites) {
            const ix = this._favourites.indexOf(kitchen);
            this._favourites.splice(ix, 1);
        }
    }
}