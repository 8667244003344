import User from "../models/user";
import React, { createContext } from "react";
import Database from "../data/database";

export interface UserContextProviderProps {

}

export interface UserContextProviderState {
    user?: User
}

interface IUserContext {
    user?: User;
    setUser: (u?: User) => void;
}

export const UserContext = createContext<IUserContext>({
    setUser: () => { },
    user: undefined
});


class UserContextProvider extends React.Component<UserContextProviderProps, UserContextProviderState> {
    state: UserContextProviderState = {
        user: undefined
    }
    private _setUser = (user?: User) => {
        console.log("set user triggered", user)
        this.setState({
            user: user
        })
    }
    render() {
        const contextData: IUserContext = {
            setUser: this._setUser,
            user: this.state.user
        }
        return (
            <UserContext.Provider value={contextData}>
                {this.props.children}
            </UserContext.Provider>);
    }
}

export default UserContextProvider;