import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

export interface WhySignupViewProps {

}

export interface WhySignupViewState {

}

class WhySignupView extends React.Component<WhySignupViewProps, WhySignupViewState> {
    render() {
        return (
            <div className="mt-3">
                <Link to="/">
                    <Button>
                        Back
            </Button>
                </Link>
                <div className="center mt-5">

                    <h1 className="text-center">Why sign up a Kitjin account?</h1>
                    <div className="mt-5 ">
                        We're still working on features to improve your experience with Kitjin, and while you can easily enjoy the primary features forever without
                        creating an account, you will miss out on some convenience features that hopefully would improve your experience even more!
                </div>
                    <div className="mt-3">
                        Currently, the features you get are:
                    <br />
                        <br />
                        <ul>
                            <li>Save kitchens to your profile, so you can access them anywhere, even when you forget your kitchen ID!</li>
                            <li>Ability to set a primary kitchen, that will be readily avaible whenver you open the app!</li>
                        </ul>
                    </div>
                    <div>
                        We are obviously also working on future features, including:
                    <br />
                        <br />
                        <ul>
                            <li>Invite only kitchens, so that grumpy ex-housemates cannot access your Kitjin space</li>
                            <li>Custom kitchen id!</li>
                            <li>Schedules for cleaning, and even email/phone reminders!</li>
                            <li>Even more!?</li>
                        </ul>
                    </div>
                    <div>
                        We obviously do take your privacy incredible serious. We only store the barebone needed information that we need to make Kitjin more convenient!
                </div>
                </div>
            </div>
        );
    }
}

export default WhySignupView;