import React, { createContext } from 'react';
import './App.css';
import { BrowserRouter, Switch, Route, useParams } from 'react-router-dom'
import AddItemView from './views/add-item-view';
import KitchenView from './views/kitchen-view';
import MainView from './views/main-view';
import ProfileView from './views/profile-view';
import SignInView from './views/signin-view';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import SignInEmailView from './views/signin-email-view';
import User from './models/user';
import UserContextProvider from './contexts/user-context';
import WhySignupView from './views/why-signup';


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <UserContextProvider>
          <BrowserRouter>
            <SignInView></SignInView>
            <Container fluid="sm">
              <Switch>
                <Route path="/profile">
                  <ProfileView></ProfileView>
                </Route>
                <Route path="/kitchen/:id/">
                  <Kitchen />
                </Route>
                <Route path="/kitchen/">
                  <MainView></MainView>
                </Route>
                <Route path="/email">
                  <SignInEmailView></SignInEmailView>
                </Route>
                <Route path="/whysignup">
                  <WhySignupView />
                </Route>
                <Route path="/">
                  <MainView></MainView>
                </Route>
              </Switch>
            </Container>
          </BrowserRouter>
        </UserContextProvider>
      </header>
    </div>
  );

}
function _handleUserChange(userPrev: User | undefined, userNext: User | undefined) {
  return 1;
}
function Kitchen() {
  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.
  let { id } = useParams();

  return (
    <div>
      <KitchenView kitchenId={id || ""}></KitchenView>
    </div>
  );
}
export default App;
