import React, { ChangeEvent } from "react";
import IItem from "../interfaces/item";
import { addItem, allCategories } from "../data/firestore-provider";
import Item from "../models/item";
import Kitchen from "../models/kitchen";
import { Button } from "react-bootstrap";

export interface AddItemViewProps {
    kitcen?: Kitchen | null
}

export interface AddItemViewState {
    name: string;
    category: string;
    submitting: boolean;
}

class AddItemView extends React.Component<AddItemViewProps, AddItemViewState> {
    state: AddItemViewState = {
        name: "",
        category: allCategories[0],
        submitting: false
    }
    render() {
        return (<div className="center" style={{ textAlign: "center" }}>
            <h3 className="mb-3">Add item to grocery list</h3>
            <input
                className="mb-3"
                type="text"
                placeholder="New item"
                value={this.state.name}
                onChange={this._handleOnChange} />
            <select className="mb-3"
                name="all categories"
                id="cat"
                value={this.state.category}
                onChange={this._handleSelectChange}>
                {
                    allCategories.map(cat =>
                        <option key={cat} id={cat} value={cat}>{
                            cat
                        }</option>
                    )
                }
            </select>
            <Button className="mb-3" as="button" onClick={this._handleSubmit}>{
                this.state.submitting ? "Submitting..." : "Submit"
            }</Button>
        </div>);
    }

    private _handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        this.setState({
            name: val
        })
    }
    private _handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const val = e.target.value;
        this.setState({
            category: val
        })
    }
    private _handleSubmit = async (_: any) => {
        if (this.state.submitting) return;
        if (!this.props.kitcen) return;
        if (!this.state.name) return;
        this.toggleSubmit()
        const data: IItem = {
            name: this.state.name,
            needed: false,
            category: this.state.category
        }
        await addItem(new Item("", this.props.kitcen.id, data));
        this.toggleSubmit()
        this.setState({
            name: ""
        })
    }
    private toggleSubmit = () => {
        this.setState({
            submitting: !this.state.submitting
        })
    }
}

export default AddItemView;