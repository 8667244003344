import React from "react";
import { loginWithGoogle, userStream, loginWithFacebook } from "../data/firestore-provider";
import Database from "../data/database";
import { Link, } from "react-router-dom";
import { Button, Navbar, Row } from "react-bootstrap";
import User from "../models/user";
import UserContextProvider, { UserContext } from "../contexts/user-context";

export interface SignInViewProps {

}

export interface SignInViewState {
    user?: User;
    show_signin: boolean;
    loggingIn: boolean;
}



class SignInView extends React.Component<SignInViewProps, SignInViewState> {
    state: SignInViewState = {
        show_signin: false,
        loggingIn: false
    }

    static contextType = UserContext;

    render() {
        this._authStream()
        const user = this.state.user;
        const logginIn = this.state.loggingIn;
        if (user) console.log("user logged in!")
        console.log("sign in context", this.context)
        return (
            <div>
                <Navbar bg="dark" expand="sm">
                    {
                        user ? this._displayMenu() : logginIn ? <div>Logging in...</div> : this._displaySigninOptions()
                    }
                </Navbar>
            </div>);
    }

    private _handleSignInFacebook = async (_: any) => {
        this.setState({
            loggingIn: true
        })
        await loginWithFacebook().catch((e) => {
            window.alert(e);
        });
        this.setState({
            loggingIn: false
        })
    }

    private _handleSignInGoogle = async (_: any) => {
        this.setState({
            loggingIn: true
        })
        await loginWithGoogle()
        this.setState({
            loggingIn: false
        })

    }

    private _displaySigninOptions = () => {
        if (this.state.show_signin) return (<div>
            <Row>
                <Link className="ml-3" to="/">
                    <Button variant="outline-danger" onClick={this._handleSignInGoogle}>Google</Button>
                </Link>
                <Link className="ml-3" to="/">
                    <Button variant="outline-primary" onClick={this._handleSignInFacebook}>Facebook</Button>
                </Link>
                <Link to="/email">
                    <Button variant="outline-light" className="ml-3">
                        Email
                        </Button>
                </Link>
            </Row>
        </div >);
        return (
            <div>
                <Button className="mr-3" onClick={this._handleToggleSignInMenu}>
                    Sign in
            </Button>
                <Link to="/whysignup">
                    <Button variant="outline-light">
                        Why?
                    </Button>
                </Link>
            </div>
        );
    }


    private _displayMenu = () => {
        const user = this.state.user;
        const showPrimary = user?.primaryKitchen !== ""
        if (user)
            return (
                <Row className="justify-content-md-center">
                    <Link to="/" className="nav-link" key="main">
                        <Button variant="outline-light" >
                            Main
                    </Button>
                    </Link>
                    <Link to="/profile" className="nav-link" key="favs">
                        <Button variant="outline-primary" >
                            Profile
                    </Button>
                    </Link>{
                        showPrimary ?
                            <Link to={`/kitchen/${user.primaryKitchen}`} className="nav-link" key="primary">
                                <Button variant="outline-primary">
                                    Primary
                        </Button>
                            </Link> : <></>
                    }
                </Row>
            )
        return <div></div>
    }
    private _handleToggleSignInMenu = (_: any) => {
        this.setState({
            show_signin: !this.state.show_signin
        })
    }
    private _authStream = async () => {
        const currentUser = Database.instance().user
        userStream((newUser) => {
            if (!currentUser && newUser) {
                this.setState({
                    user: newUser
                })
            }
            if (currentUser && !newUser) {
                this.setState({
                    user: undefined
                })
            }
        })
    }
}

export default SignInView;