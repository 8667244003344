import IDuty from "../interfaces/duty";

export default class Duty {
    private _duty: IDuty;
    private _id: string;
    private _kitchenId: string;

    constructor(id: string, kitchenId: string, duty: IDuty) {
        this._id = id;
        this._duty = duty;
        this._kitchenId = kitchenId
    }
    public get name() {
        return this._duty.name || "";
    }
    public get id() {
        return this._id || "";
    }
    public toggleStatus() {
        this._duty.done = !this._duty.done;
        console.log(this._duty.done);
    }
    public get isDone() {
        return this._duty.done || false;
    }
    public get json() {
        return this._duty;
    }
    public get kitchenId() {
        return this._kitchenId;
    }
    public set done(done:boolean){
        this._duty.done = done;
    }
}