import React, { ChangeEvent } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { loginWithEmail, createAccountEmail } from "../data/firestore-provider";
import Database from "../data/database";
import { Link } from "react-router-dom";

export interface SignInEmailViewProps {

}

export interface SignInEmailViewState {
    emailLogn: string;
    passwordLogin: string;
    emailSignup: string;
    nameSignup: string;
    passwordSignup: string;
    passwordRepeatSignup: string;
    showPwdLogin: boolean;
    showPwdSignup: boolean;
    showPwdRptSignup: boolean;
    loadingLogin: boolean;
    loadingSignup: boolean;
}

class SignInEmailView extends React.Component<SignInEmailViewProps, SignInEmailViewState> {
    state: SignInEmailViewState = {
        emailLogn: "",
        passwordLogin: "",
        emailSignup: "",
        nameSignup: "",
        passwordSignup: "",
        passwordRepeatSignup: "",
        showPwdLogin: false,
        showPwdRptSignup: false,
        showPwdSignup: false,
        loadingLogin: false,
        loadingSignup: false
    }
    render() {
        if (Database.instance().user) {
            return <div>
                You are already logged in
            </div>
        }
        return (
            <Col>
                <div className="row mt-3 mb-3">
                    <Link to="/">
                        <Button>
                            Back
            </Button>
                    </Link>
                </div>
                <Row>
                    Login
                </Row>
                <Row className="mt-2">
                    <input onChange={this._handleEmailLoginChange} type="text" name="email" placeholder="Email" id="" />
                </Row>
                <Row className="mt-2">
                    <input onChange={this._handlePasswordLoginChange} type={this.state.showPwdLogin ? "text" : "password"} name="pwd" placeholder="Password" id="" />
                    {
                        this._togglePwdLogin()
                    }
                </Row>
                <Row className="mt-2">
                    <Button onClick={this._handleLogin} variant="success">{
                        this.state.loadingLogin ? "Logging in..." : "Login"
                    }</Button>

                </Row>
                <Row className="mt-5">
                    Sign up
                </Row>
                <Row className="mt-2">
                    <input onChange={this._handleNameChange} type="text" name="name" placeholder="Name" id="" />
                </Row >
                <Row className="mt-2">
                    <input onChange={this._handleEmailSignupChange} type="text" name="email" placeholder="Email" id="" />
                </Row>
                <Row className="mt-2">
                    <input onChange={this._handlePwdSignupChange} type={this.state.showPwdSignup ? "text" : "password"} name="pwd" placeholder="Password (min. 6 chars)" id="" />
                    {
                        this._togglePwdSignup()
                    }
                </Row>
                <Row className="mt-2">
                    <input onChange={this._handlePwdRptChange} type={this.state.showPwdRptSignup ? "text" : "password"} name="pwdrepeat" placeholder="Repeat password" id="" />
                    {
                        this._togglePwdRptSignup()
                    }
                </Row>
                <Row className="mt-2">
                    <Button onClick={this._handleSignup} variant="success">{
                        this.state.loadingSignup ? "Submitting" : "Sign up"
                    }</Button>
                </Row>
            </Col>
        );
    }
    private _handleLogin = async (e: any) => {
        if (this.state.loadingLogin) return;
        this.setState({
            loadingLogin: true
        })
        const email = this.state.emailLogn;
        const password = this.state.passwordLogin;
        if (email === "" || password === "") return;
        await loginWithEmail(email, password)
        this.setState({
            loadingLogin: false
        })
    }

    private _handleSignup = async (e: any) => {
        if (this.state.loadingSignup) return;
        this.setState({
            loadingSignup: true
        })
        const email = this.state.emailSignup;
        const name = this.state.nameSignup;
        const pwd = this.state.passwordSignup;
        const pwdrpt = this.state.passwordRepeatSignup;
        if (email === "" || name === "" || pwd === "" || pwdrpt === "") return;
        if (pwd !== pwdrpt) {
            window.alert("Password do not match");
            return
        }
        if (pwd.length < 6) {
            window.alert("Password is too short");
            return
        }
        await createAccountEmail(name, email, pwd);
        this.setState({
            loadingSignup: false
        })
    }

    // Text handlers

    private _handleEmailLoginChange = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            emailLogn: e.target.value
        })
    }

    private _handlePasswordLoginChange = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            passwordLogin: e.target.value
        })
    }

    private _handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            nameSignup: e.target.value
        })
    }
    private _handleEmailSignupChange = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            emailSignup: e.target.value
        })
    }

    private _handlePwdSignupChange = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            passwordSignup: e.target.value
        })
    }

    private _handlePwdRptChange = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            passwordRepeatSignup: e.target.value
        })
    }


    //! Eyes
    private _togglePwdLogin = () => {
        return (<Button variant="outline-dark" onClick={(e: any) => {
            this.setState({
                showPwdLogin: !this.state.showPwdLogin
            })
        }}>{
                this.state.showPwdLogin ? "🕶️" : "👀"
            }</Button>);
    }
    private _togglePwdSignup = () => {
        return (
            <Button variant="outline-dark" onClick={(e: any) => {
                this.setState({
                    showPwdSignup: !this.state.showPwdSignup
                })
            }}>{
                    this.state.showPwdSignup ? "🕶️" : "👀"
                }</Button>);
    }
    private _togglePwdRptSignup = () => {
        return (<Button variant="outline-dark" onClick={(e: any) => {
            this.setState({
                showPwdRptSignup: !this.state.showPwdRptSignup
            })
        }}>{
                this.state.showPwdRptSignup ? "🕶️" : "👀"
            }</Button>);
    }
}


export default SignInEmailView;