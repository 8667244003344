import React, { ChangeEvent } from "react";
import { getNewKitchen } from "../data/firestore-provider";
import { Button } from "react-bootstrap";

export interface MainViewProps {

}

export interface MainViewState {
    kitchenName: string;
    existingId: string;
    settinUp: boolean;
    uniqueCode: string;
}

class MainView extends React.Component<MainViewProps, MainViewState> {
    state: MainViewState = {
        kitchenName: "",
        settinUp: false,
        existingId: "",
        uniqueCode: ""
    }
    render() {
        const settingUp = this.state.settinUp
        const name = this.state.kitchenName
        const kitchenIdPreview = this._getTrimmedKitchenName() !== "" ? this._getKitchenId() : ""
        this._generateUniqueCode()
        return (
            <div>
                {
                    settingUp ? "Setting up kitchen..." + name :
                        <div className="col center" style={{ textAlign: "center" }}>
                            <h3 className="mt-3 mb-3">Welcome to Kitjin!</h3>
                            <h5 className="mb-5">A personal app for your shared kitchen</h5>
                            <label className="row" htmlFor="name">
                                What's the name of your kitchen?
                            </label>
                            <div className="row mb-3" style={{ color: "grey" }}>
                                (We'll add groceries and cleaning duties!)
                            </div>
                            <input className="col-lg col-auto" type="text" name="name" placeholder="Your kitchen's name" id="name" onChange={this._handleNameChange} />
                            <Button variant="outline-success" className="col col-auto mt-2" onClick={this._addKitchen} >Get your new kitchen space!</Button>
                            <div className="mt-3 ">{
                                kitchenIdPreview
                            }</div>
                            <br />
                            <br />
                            <label htmlFor="id" className="row ">
                                Or enter an id of an existing kitchen
                            </label>
                            <div className="row mb-5">
                                <input className="col"
                                    value={this.state.existingId}
                                    placeholder="Enter existing kitchen ID"
                                    type="text" name="name" id="id"
                                    onChange={this._handleExistingId} />
                                <Button variant="outline-primary" className="col col-auto ml-2" onClick={this._getExistingKitchen}>{"🔍"}</Button>
                            </div>
                        </div>
                }
            </div>
        );
    }

    private _addKitchen = async (_: any) => {
        if (this._getTrimmedKitchenName() === "") {
            return;
        }
        this.setState({
            settinUp: true
        })
        await getNewKitchen(this.state.kitchenName, this._getKitchenId())
    }
    private _getExistingKitchen = (_: any) => {
        if (this.state.existingId === "") {
            window.alert("Enter ID");
            return
        }
        window.location.href = window.location.origin + "/kitchen/" + this.state.existingId;
    }
    private _handleExistingId = (e: ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        this.setState({
            existingId: val
        })
    }
    private _handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        this.setState({
            kitchenName: val
        })
    }
    private _generateUniqueCode = () => {
        if (this.state.uniqueCode !== "") return;
        const alphabet = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'w', 'v', 'x', 'y', 'z', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        let code = ""
        for (let i = 0; i < 4; i++) {
            const rand = Math.random();
            const index = Math.floor(rand * alphabet.length)
            code += alphabet[index];
        }
        this.setState({
            uniqueCode: code
        })
    }
    private _getTrimmedKitchenName = () => {
        const name = this.state.kitchenName;
        return name.replace(/[^a-zA-Z0-9]/gi, "").toLowerCase();
    }
    private _getKitchenId = () => {
        return this._getTrimmedKitchenName() + "-" + this.state.uniqueCode
    }
}

export default MainView;