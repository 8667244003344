import Item from "../models/item";
import React from "react";
import ItemComponent from "../components/item";

export interface GroceriesViewProps {
    items?: Item[]
    removable: boolean;
}

export interface GroceriesViewState {

}

class GroceriesView extends React.Component<GroceriesViewProps, GroceriesViewState> {
    render() {
        const items = this.props.items;
        return (
            <div>
                {
                    items ?
                        items.map((i) => (
                            <ItemComponent key={i.id} removable={this.props.removable} item={i}></ItemComponent>
                        )
                        ) :
                        "Loading items..."
                }
            </div>
        );
    }
}

export default GroceriesView;