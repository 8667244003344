import Duty from "../models/duty";
import React from "react";
import { toggleDutyStatus } from "../data/firestore-provider";
import { Row, Col, Button } from "react-bootstrap";

export interface DutyComponentProps {
    duty: Duty;
}

export interface DutyComponentState {
    loading: boolean;
}

class DutyComponent extends React.Component<DutyComponentProps, DutyComponentState> {
    state: DutyComponentState = { loading: false }
    render() {
        const duty = this.props.duty;
        return (
            <Row className="mb-3">
                <Col>
                    {
                        duty.name
                    }
                </Col>
                <Col className="col-auto">
                    <Button variant={duty.isDone ? "success" : "danger"} onClick={this._handleStatusChange}>
                        {
                            this.buttonText()
                        }
                    </Button>
                </Col>
            </Row>

        );
    }
    private _handleStatusChange = async (e: any) => {
        if (this.state.loading) return;
        this.props.duty.toggleStatus()
        this.setState({
            loading: true
        })
        await toggleDutyStatus(this.props.duty)
        this.setState({
            loading: false
        })
    }

    private buttonText = () => {
        const duty = this.props.duty
        if (this.state.loading) return "Submitting...";
        if (duty.isDone) return "Done"
        return "Not done"

    }
}

export default DutyComponent;