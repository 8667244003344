import React from "react";
import Item from "../models/item";
import { toggleItemStatus, removeItem } from "../data/firestore-provider";
import { Button, Col, Row } from "react-bootstrap";

export interface ItemProps {
    item: Item;
    removable: boolean;
}

export interface ItemState {
    item: Item;
    loading: boolean;
    removed: boolean;
}

class ItemComponent extends React.Component<ItemProps, ItemState> {

    state: ItemState = {
        item: this.props.item,
        loading: false,
        removed: false
    }
    render() {
        const item = this.state.item;
        const btnClass = item.isNeeded ? "danger" : "secondary";
        return (
            <Row key={item.id} className="mb-3">
                <Col >
                    {
                        item.name
                    }
                </Col>
                <Col className="col-auto">
                    <Button variant={btnClass} onClick={this._handleStatusChange}>{
                        this.buttonText()
                    }</Button>
                </Col>
                {
                    this._displayRemovable()
                }
            </Row>
        );
    }
    private _displayRemovable = () => {
        const removable = this.props.removable;
        const removed = this.state.removed;
        if (!removable) return;
        if (removed) return (
            <Col style={{ fontSize: 15 }} className="col-auto">
                Removed
            </Col>
        )
        return (
            <Col className="col-auto">
                <Button className="btn-danger btn-sm" onClick={this._handleDelete} >x</Button>
            </Col>
        )
    }

    private _handleDelete = async (_: any) => {
        this.setState({
            removed: true
        })
        await removeItem(this.state.item)
    }

    private _handleStatusChange = async (_: any) => {
        if (this.state.loading) return;
        this.state.item.toggleStatus()
        this.setState({
            loading: true
        })
        await toggleItemStatus(this.state.item)
        this.setState({
            loading: false
        })
    }

    private buttonText = () => {
        if (this.state.loading) return "Submitting...";
        if (this.state.item.isNeeded) return "Need"
        return "Have"

    }
}

export default ItemComponent;