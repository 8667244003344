import React, { ChangeEvent } from "react";
import Duty from "../models/duty";
import DutyComponent from "../components/duty-component";
import { resetDuties, addCleaningLog, getCleaningLogs } from "../data/firestore-provider";
import CleaningLog from "../models/cleaning_log";
import CleaningLogComponent from "../components/log-component";
import { Row, Col, Button } from "react-bootstrap";

export interface DutiesViewProps {
    duties?: Duty[];
    kitchenId: string;
}

export interface DutiesViewState {
    resetting: boolean;
    responsible: string;
    logs?: CleaningLog[] | null
}

class DutiesView extends React.Component<DutiesViewProps, DutiesViewState> {
    state: DutiesViewState = {
        resetting: false,
        responsible: "",
        logs: undefined
    }
    render() {
        const duties = this.props.duties;
        const resetting = this.state.resetting
        const logs = this.state.logs
        if (logs === undefined)
            this._getCleaningLogs()
        return (
            <div>
                {
                    duties ?
                        duties.map((d) => (
                            <DutyComponent key={d.id} duty={d}></DutyComponent>
                        )) :
                        "Loading items..."
                }
                <Col className="mt-5">
                    <Row >
                        <input className="col" value={this.state.responsible} type="text" name="Responsible" id="responsible" placeholder="Responsible" onChange={this._handleResponsible} />
                        <Button className="col col-auto ml-2" variant="success" onClick={this._addLogAndReset}>{
                            resetting ? "Submitting" : "Add log"
                        }</Button>
                    </Row>
                    <Row className="center mt-3 mb-5">
                        <Button variant="outline-danger" onClick={this._resetDuties}>{
                            resetting ? "Resetting..." : "Reset all duties"
                        }</Button>
                    </Row>
                </Col>
                <Row>
                    <h3>Logs</h3>
                </Row>
                {
                    this._displayLogs()
                }
            </div>
        );
    }
    private _handleResponsible = async (e: ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        this.setState({
            responsible: val
        })
    }
    private _resetDuties = async (_: any) => {
        if (this.state.resetting) return;
        this._toggleReset()
        if (!this.props.duties) return;
        await resetDuties(this.props.duties)
        this._toggleReset()
    }
    private _addLogAndReset = async (_: any) => {
        if (this.state.resetting) return;
        if (!this.props.duties) return;
        if (this.state.responsible === "") {
            window.alert("Enter a responsible: either a name or a room number")
            return
        }
        this._toggleReset()

        const log = new CleaningLog("", this.props.kitchenId, {
            responsible: this.state.responsible,
            date: new Date()
        })
        await Promise.all([
            resetDuties(this.props.duties),
            addCleaningLog(log)
        ])
        this.setState({
            resetting: false,
            logs: undefined,
            responsible: ""
        })
    }
    private _toggleReset = () => {
        this.setState({
            resetting: !this.state.resetting
        }
        );
    }
    private _getCleaningLogs = async () => {
        const logs = await getCleaningLogs(this.props.kitchenId);
        if (logs === undefined) {
            this.setState({
                logs: null
            })
            return;
        }
        this.setState({
            logs: logs
        })
    }
    private _displayLogs = () => {
        const logs = this.state.logs;
        if (logs === undefined) return "Logs loading..."
        if (logs === null) return "Error getting logs";
        if (logs.length === 0) return "No logs yet";
        return logs.map((log) => {
            return (
                <CleaningLogComponent log={log}></CleaningLogComponent>
            )
        })
    }
}

export default DutiesView;