import Item from "../models/item";
import IItem from "../interfaces/item";
import firebase from 'firebase'
import IKitchen from "../interfaces/kitchen";
import Kitchen from "../models/kitchen";
import IDuty from "../interfaces/duty";
import Duty from "../models/duty";
import ICleaningLog, { ICleaningLogFirestore } from "../interfaces/cleaning_log";
import CleaningLog from "../models/cleaning_log";
import IFavourite from "../interfaces/favourite";
import User from "../models/user";
import IUSer, { IUserFirestore } from "../interfaces/user";
import Database from "./database";

const firebaseConfig = {
    apiKey: "AIzaSyCtg0gj6jgkeptSNgyOZk3DDOa54u1Mo68",
    authDomain: "dorm-kitchen.firebaseapp.com",
    databaseURL: "https://dorm-kitchen.firebaseio.com",
    projectId: "dorm-kitchen",
    storageBucket: "dorm-kitchen.appspot.com",
    messagingSenderId: "20444441531",
    appId: "1:20444441531:web:4ffd82386c27807e60b29e"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore()
const kitchensCollection = firestore.collection("kitchens");
const favsCollection = firestore.collection("favourites");
const usersCollection = firestore.collection("users")
const dutiesCollection = (kitchenId: string) => kitchensCollection.doc(kitchenId).collection("duties")
const groceriesCollection = (kitchenId: string) => kitchensCollection.doc(kitchenId).collection("groceries")
const logsCollection = (kitchenId: string) => kitchensCollection.doc(kitchenId).collection("cleaning_logs");

const catCleaning = "cleaning";
const catUtility = "utility";
const catFood = "food";
const catStorage = "storage";
const catMisc = "misc"
export const allCategories = [catCleaning, catFood, catStorage, catUtility, catMisc]



export async function toggleItemStatus(item: Item) {
    const updateData: IItem = {
        needed: item.isNeeded
    }
    await groceriesCollection(item.kitchenId).doc(item.id).update(updateData).catch(console.error);
    return "OK";
}

export async function toggleDutyStatus(duty: Duty) {
    const updateData: IDuty = {
        done: duty.isDone
    }
    await dutiesCollection(duty.kitchenId).doc(duty.id).update(updateData).catch(console.error);
    return "OK";
}


export async function addItem(item: Item) {
    await groceriesCollection(item.kitchenId).add(item.json).catch(console.error)
}

export async function removeItem(item: Item) {
    return await groceriesCollection(item.kitchenId).doc(item.id).delete().catch((e) => {
        console.error(e);
        return undefined
    });
}
export async function removeDuty(duty: Duty) {
    return await dutiesCollection(duty.kitchenId).doc(duty.id).delete().catch(console.error);
}



export async function getKitchen(id: string) {
    const snap = await kitchensCollection.doc(id).get().catch(console.error);
    if (!snap || !snap.exists) return undefined;
    const data = snap.data() as IKitchen;
    return new Kitchen(snap.id, data);
}
export async function getDuties(kitchenId: string) {
    const snap = await dutiesCollection(kitchenId).get().catch(console.error);
    if (!snap) return undefined;
    if (snap.empty) return []
    const docs = snap.docs;
    const duties: Duty[] = []
    for (const doc of docs) {
        const docData = doc.data() as IDuty
        const duty = new Duty(doc.id, kitchenId, docData);
        duties.push(duty)
    }
    const sorted = duties.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0)
    return sorted;
}
export async function getGroceries(kitchenId: string) {
    const snap = await groceriesCollection(kitchenId).orderBy("name").get().catch(console.error);
    if (!snap) return undefined;
    if (snap.empty) return [];
    const docs = snap.docs;
    const items: Item[] = []
    for (const doc of docs) {
        const docData = doc.data() as IItem;
        const item = new Item(doc.id, kitchenId, docData);
        items.push(item);
    }
    const sorted = items.sort(sortItems)
    return sorted;
}

function sortItems(a: Item, b: Item) {
    if (!a.isNeeded && b.isNeeded) return 1;
    if (a.isNeeded && !b.isNeeded) return -1;
    if (a.name > b.name) return 1;
    if (a.name < b.name) return -1;
    return 0;
}

export async function addGrocery(kitchenId: string, item: Item) {
    return await groceriesCollection(kitchenId).add(item.json).catch(console.error);
}

export async function getNewKitchen(name: string, id: string) {
    const data: IKitchen = {
        name: name,
        creation_day: new Date(),
        created_by: "anonymous"
    }
    const user = Database.instance().user;
    if (user) data.created_by = user.name // apply user name if logged in
    const doc = await kitchensCollection.doc(id).get().catch(console.error);
    if (doc && doc.exists) {
        alert("Our bad! This kitchen already exists. We'll generate a new ID for you so you can try again")
        window.location.reload()
        return;
    }
    await kitchensCollection.doc(id).set(data).catch(console.error)
    await addGroceries(id);
    await addDuties(id);
    window.location.search = "";
    const url = window.location.origin + "/kitchen/" + id
    console.log(url)
    window.location.href = url;
}

export async function resetDuties(duties: Duty[]) {
    const batch = firebase.firestore().batch()
    duties.forEach((duty) => {
        duty.done = false;
        const doc = dutiesCollection(duty.kitchenId).doc(duty.id);
        batch.update(doc, duty.json)
    })
    await batch.commit().catch(console.error)
}

async function addGroceries(kitchenId: string) {
    const cleaning = ["Cleaning alcohol", "Cleaning gloves", "Dish brush", "Dish detergent", "Oven cleaner", "Sponges", "Wipe cloths", "Hand soap"].map((i) => mapGrocery(i, "cleaning"))
    const storage = ["Aluminium foil", "Baking paper", "Plastic bags 4L", "Plastic wrap"].map((i) => mapGrocery(i, "storage"))
    const util = ["Paper towels"].map((i) => mapGrocery(i, "utility"))
    const food = ["Salt", "Pepper"].map((i) => mapGrocery(i, catFood))
    const groceries = [...cleaning, ...storage, ...util, ...food]
    const batch = firebase.firestore().batch()
    groceries.forEach((item) => {
        const doc = groceriesCollection(kitchenId).doc()
        batch.set(doc, item);
        console.log(item.name + " added")
    })
    await batch.commit().catch(console.error)
}

//! Duties
export async function addDuty(duty: Duty) {
    await dutiesCollection(duty.kitchenId).add(duty.json).catch(console.error)
}

async function addDuties(kitchenId: string) {
    const duties = ["Sweep floor", "Wipe surfaces", "Wipe handles", "Clean kettle", "Clean microwave", "Clean oven", "Clean toaster", "Clean stove", "Recycle trash", "Wash dishcloths"].map(mapDuties)
    const batch = firebase.firestore().batch()
    duties.forEach((item) => {
        const doc = dutiesCollection(kitchenId).doc()
        batch.set(doc, item);
        console.log(item.name + " added")
    })
    batch.commit().catch(console.error)
}

function mapGrocery(grocery: string, category: string) {
    const item: IItem = {
        name: grocery,
        needed: false,
        category: category
    }
    return item;
}

function mapDuties(duty: string) {
    const data: IDuty = {
        name: duty,
        done: true
    }
    return data;
}

export async function addCleaningLog(log: CleaningLog) {
    return await logsCollection(log.kitchenId).add(log.json).catch(console.error);
}

export async function getCleaningLogs(kitchenId: string) {
    const snap = await logsCollection(kitchenId).get().catch(console.error);
    if (!snap) return undefined;
    if (snap.empty) return [];
    console.log(snap.size)
    const docs = snap.docs;
    const docMap = docs.map((doc) => {
        const docData = doc.data() as ICleaningLogFirestore
        const logData: ICleaningLog = {
            responsible: docData.responsible || "",
            date: docData.date!.toDate() || Date.now()
        }
        return new CleaningLog(doc.id, kitchenId, logData);
    });
    const sorted = docMap.sort((a, b) => a.date > b.date ? -1 : a.date < b.date ? 1 : 0)
    return sorted;
}


//! Favourites
function getFavId(fav: IFavourite) {
    return fav.user_id + "-" + fav.kitchen_id;
}
export async function addFavourite(user: User, kitchen: Kitchen) {
    const favData: IFavourite = {
        kitchen_id: kitchen.id,
        user_id: user.id
    }
    const docId = getFavId(favData);
    await favsCollection.doc(docId).set(favData).catch(console.error)
    return "OK";
}

export async function removeFavourite(user: User, kitchen: Kitchen) {
    const favData: IFavourite = {
        kitchen_id: kitchen.id,
        user_id: user.id
    }
    const docId = getFavId(favData);
    await favsCollection.doc(docId).delete().catch(console.error);
    return "OK";
}

export async function getFavouries(userId: string) {
    const snap = await favsCollection.where("user_id", "==", userId).get().catch(console.error);
    if (!snap) return null;
    if (snap.empty) return []
    console.log(snap.size)
    const docs = snap.docs;
    const kitchens: Kitchen[] = []
    for (const doc of docs) {
        const docData = doc.data() as IFavourite
        const kitchenSnap = await kitchensCollection.doc(docData.kitchen_id).get();
        if (!kitchenSnap) continue;
        if (!kitchenSnap.exists) continue;
        const kitchenData = kitchenSnap.data() as IKitchen;
        const newKitchen = new Kitchen(kitchenSnap.id, kitchenData);
        kitchens.push(newKitchen);
    }
    return kitchens;
}

export async function isKitchenFavourite(user: User, kitchen: Kitchen) {
    if (!user || !kitchen) return false;
    const favData: IFavourite = {
        kitchen_id: kitchen.id,
        user_id: user.id
    }
    const docId = getFavId(favData);
    const doc = await favsCollection.doc(docId).get().catch(console.error);
    if (!doc) return false;
    if (!doc.exists) return false;
    return true;
}

export async function setPrimaryFirestore(user: User) {
    const updateData: IUserFirestore = {
        primary_kitchen: user.primaryKitchen
    }
    usersCollection.doc(user.id).update(updateData).catch(console.error);
    console.log("primary set", user.primaryKitchen)
    return true
}

async function getPrimaryKitchen(user: User) {
    const docSnap = await usersCollection.doc(user.id).get().catch(console.log)
    if (!docSnap || !docSnap.exists) return ""
    const data = docSnap.data() as IUserFirestore;
    const primary = data.primary_kitchen;
    if (!primary) return "";
    return primary;
}

//! AUTH

export async function loginWithEmail(email: string, password: string) {
    await firebase.auth().signInWithEmailAndPassword(email, password).catch((e) => {
        console.error(e);
        window.alert(e)
    });
    window.location.href = window.location.origin
}
export async function createAccountEmail(name: string, email: string, password: string) {
    const user = await firebase.auth().createUserWithEmailAndPassword(email, password).catch((e) => {
        console.error(e);
        window.alert(e)
    })
    if (user && user.user) {
        await user.user.updateProfile({
            displayName: name
        })
        window.location.href = window.location.origin
        await firebase.auth().updateCurrentUser(user.user)
    }
}

export async function loginWithGoogle() {
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    const provider = new firebase.auth.GoogleAuthProvider();
    const result = await firebase.auth().signInWithPopup(provider).catch((e) => {
        console.error(e);
        window.alert(e)
        throw e;
    });
    if (!result) return null;
    return result.user;
}
export async function loginWithFacebook() {
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    const provider = new firebase.auth.FacebookAuthProvider();
    provider.addScope("email");
    provider.addScope("public_profile");
    const result = await firebase.auth().signInWithPopup(provider).catch((e) => {
        window.alert(e);
        if (e.code === 'auth/account-exists-with-different-credential') {
            firebase.auth().fetchSignInMethodsForEmail(e.email).then(function (providers) {
                console.log(providers);
            });
        }
        throw e;
    });
    if (!result) return null;
    console.log(result)
    return result.user;
}
export async function signOut() {
    await firebase.auth().signOut();
    Database.signOff()
}

export function userStream(setStateCb: (user: User | undefined) => void) {
    firebase.auth().onAuthStateChanged(async (u) => {
        if (u === null) {
            console.log("user is null")
            setStateCb(undefined);
            return Database.signOff();
        }
        let user = Database.instance().user;

        if (!user) {
            const userData: IUSer = {
                id: u.uid,
                name: u.displayName || "",
            }
            user = new User(userData)
            user.primaryKitchen = await getPrimaryKitchen(user);
            Database.instance(user)
        }
        if (user.favourites === null) {
            const favs = await getFavouries(u.uid);
            user.favourites = favs;
        }
        const redirect = "/kitchen/" + user.primaryKitchen;
        if (user.primaryKitchen !== "" && window.location.pathname === "/")
            window.location.href = window.location.origin + redirect
        setStateCb(user)
    })
}
export const getUser = () => {
    return firebase.auth().currentUser;
}