import IItem from "../interfaces/item";

export default class Item {
    private _item: IItem;
    private _id: string;
    private _kitchenId: string

    constructor(id: string, kitchenId: string, item: IItem) {
        this._id = id;
        this._item = item;
        this._kitchenId = kitchenId;
    }

    public get json() {
        return this._item;
    }
    public toggleStatus() {
        this._item.needed = !this._item.needed;
        console.log(this._item.needed);
    }
    public get isNeeded() {
        return this._item.needed || false;
    }
    public get kitchenId() {
        return this._kitchenId
    }
    public get name() {
        return this._item.name || "";
    }
    public get id() {
        return this._id;
    }
}