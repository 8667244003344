import User from "../models/user";

export default class Database {
    private static _instance?: Database;
    private constructor(user?: User) {
        this._user = user;
    }

    private _user?: User;

    public get user() {
        return this._user || undefined;
    }
    public static instance(user?: User) {
        if (!this._instance || !this._instance._user) this._instance = new Database(user);
        return this._instance;
    }
    public static signOff() {
        this._instance = undefined;
        return this._instance;
    }
}