import React from "react";
import CleaningLog from "../models/cleaning_log";

export interface CleaningLogComponentProps {
    log: CleaningLog
}

export interface CleaningLogComponentState {

}

class CleaningLogComponent extends React.Component<CleaningLogComponentProps, CleaningLogComponentState> {
    render() {
        const log = this.props.log
        return (
            <div className="row log">
                {
                    log.responsible + " - " +
                    log.dateString
                }
            </div>
        );
    }
}

export default CleaningLogComponent;