import React, { ChangeEvent } from "react";
import Kitchen from "../models/kitchen";
import IDuty from "../interfaces/duty";
import Duty from "../models/duty";
import { addDuty } from "../data/firestore-provider";
import { Button} from "react-bootstrap";

export interface AddDutyViewProps {
    kitchen?: Kitchen | null
}

export interface AddDutyViewState {
    name: string;
    submitting: boolean;
}

class AddDutyView extends React.Component<AddDutyViewProps, AddDutyViewState> {
    state: AddDutyViewState = { name: "", submitting: false }
    render() {
        return (<div className="center" style={{ textAlign: "center" }}>
            <h3 className="mb-3">Add a duty</h3>
            <input
                className="mb-3"
                placeholder="New duty"
                type="text"
                value={this.state.name}
                onChange={this._handleOnChange} />
            <Button
                className="col col-auto ml-2"
                variant="success" onClick={this._handleSubmit}>{
                    this.state.submitting ? "Submitting..." : "Submit"
                }</Button>
        </div >);
    }

    private _handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        this.setState({
            name: val
        })
    }
    private _handleSubmit = async (_: any) => {
        if (this.state.submitting) return;
        if (!this.props.kitchen) return;
        this.toggleSubmit()
        const data: IDuty = {
            name: this.state.name,
            done: false,
        }
        await addDuty(new Duty("", this.props.kitchen.id, data));
        this.toggleSubmit()
        this.setState({
            name: ""
        })
    }
    private toggleSubmit = () => {
        this.setState({
            submitting: !this.state.submitting
        })
    }
}

export default AddDutyView;