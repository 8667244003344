import ICleaningLog from "../interfaces/cleaning_log";
import moment from "moment";

export default class CleaningLog {
    private _cleaningLog: ICleaningLog;
    private _id: string;
    private _kitchenId: string;

    constructor(id: string, kitchenId: string, data: ICleaningLog) {
        this._id = id;
        this._kitchenId = kitchenId;
        this._cleaningLog = data;
    }

    public get responsible() {
        return this._cleaningLog.responsible || ""
    }
    public get date() {
        return this._cleaningLog.date || Date.now()
    }
    public get dateString() {
        return moment(this.date).format("MMMM Do YYYY, h:mm:ss a").toLocaleString()
    }
    public get id() {
        return this._id;
    }
    public get kitchenId() {
        return this._kitchenId;
    }
    public get json() {
        return this._cleaningLog;
    }

}