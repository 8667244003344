import User from "../models/user";
import React from "react";
import Kitchen from "../models/kitchen";
import { userStream, getFavouries, signOut, setPrimaryFirestore } from "../data/firestore-provider";
import FavouriteComponent from "../components/favourite-component";
import Database from "../data/database";
import { Col, Button } from "react-bootstrap";
import { UserContext } from "../contexts/user-context";
import { Link } from "react-router-dom";

export interface ProfileViewProps {
}

export interface ProfileViewState {
    favourites?: Kitchen[] | null;
    user?: User | null;
    loadingSetPrimary: boolean;
}

class ProfileView extends React.Component<ProfileViewProps, ProfileViewState> {
    state: ProfileViewState = {
        favourites: undefined,
        user: Database.instance().user,
        loadingSetPrimary: false
    }
    static contextType = UserContext;

    render() {
        this._getAuth()
        this._getFavourites()
        const user = this.state.user
        if (!user) {
            return (
                <div className="mt-3 mb-3">
                    <Link to="/">
                        <Button>
                            Back
                        </Button>
                    </Link>
                    <div className="center mt-5">
                        Please login to see your profile
                    </div>
                </div>)
        }
        return (
            <div className="mt-5 center" style={{ textAlign: "center" }}>

                <h3>{
                    user.name
                }</h3>
                <h5 className="mt-3 mb-3">
                    Your saved kitchens
            </h5>
                {
                    this.displayFavs()
                }
                <Button className="mt-5" variant="outline-danger" onClick={this._handleSignOut}>
                    Sign out
                    </Button>
            </div>);
    }

    private displayFavs = () => {

        if (this.state.favourites === undefined) return "Loading favs...";
        if (this.state.favourites === null) return "Something went wrong";
        return this.state.favourites.map((fav) => {
            const isPrimary = this._isPrimary(fav);
            const btnStyle = isPrimary ? "btn btn-outline-primary" : "btn btn-outline-secondary";

            return (
                <div className="row mb-3 justify-content-md-center">
                    <FavouriteComponent key={fav.id} isPrimary={isPrimary} kitchen={fav}></FavouriteComponent>
                    <button className={`btn ${btnStyle} ml-5`} onClick={(e) => isPrimary ? this._unsetPrimary() : this._setPrimary(fav)}>{
                        this.state.loadingSetPrimary ? "submitting..." : "Primary"
                    }</button>
                </div>
            )
        })
    }

    private _unsetPrimary = async () => {
        if (this.state.loadingSetPrimary) return;
        const { setUser } = this.context
        this.setState({
            loadingSetPrimary: true
        })
        const user = Database.instance().user;
        if (user) {
            user.primaryKitchen = ""
            setUser("")
            await setPrimaryFirestore(user)
        }
        this.setState({
            loadingSetPrimary: false
        })
    }

    private _setPrimary = async (kitchen: Kitchen) => {
        if (this.state.loadingSetPrimary) return;
        const { setUser } = this.context
        this.setState({
            loadingSetPrimary: true
        })
        const user = Database.instance().user;
        if (user) {
            user.primaryKitchen = kitchen.id
            setUser(user);
            await setPrimaryFirestore(user)
        }
        this.setState({
            loadingSetPrimary: false
        })
    }
    private _isPrimary(kitchen: Kitchen) {
        const user = Database.instance().user;
        if (user)
            return user.primaryKitchen === kitchen.id;
        return false;
    }

    private _handleMainPage = () => {
        window.location.href = window.location.origin;
    }
    private _getFavourites = async () => {
        if (this.state.favourites !== undefined) return;
        if (!this.state.user) return;
        const kitchens = this.state.user.favourites || await getFavouries(this.state.user.id);
        this.setState({
            favourites: kitchens
        })
    }

    private _handleSignOut = async () => {
        await signOut();
        this.setState({
            user: undefined
        })
    }

    private _getAuth = () => {
        if (Database.instance().user === undefined)
            userStream((user) => {
                if (user && this.state.user === undefined) {
                    this.setState({
                        user: user
                    })
                }
                if (!user && this.state.user) {
                    this.setState({
                        user: undefined
                    })
                }
            })
    }
}

export default ProfileView;