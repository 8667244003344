import React from "react";
import Kitchen from "../models/kitchen";
import { getKitchen, getGroceries, getDuties, addFavourite, removeFavourite, isKitchenFavourite } from "../data/firestore-provider";
import Duty from "../models/duty";
import Item from "../models/item";
import DutiesView from "./duties-view";
import GroceriesView from "./groceries-view";
import AddItemView from "./add-item-view";
import AddDutyView from "./add-duty-view";
import MainView from "./main-view";
import { Helmet } from 'react-helmet';
import { Button, Col, Row, Container } from "react-bootstrap";
import Database from "../data/database";
import User from "../models/user";

export interface KitchenViewProps {
    kitchenId: string;
    display?: string
}

export interface KitchenViewState {
    kitchen?: Kitchen | null
    duties?: Duty[] | null;
    groceries?: Item[] | null;
    removable: boolean;
    isFavourite?: boolean;
}

const displayGroceries = "#groceries";
const displayDuties = "#duties";
const displayAddGroceries = "#addItem";
const displayAddDuty = "#addDuty"

class KitchenView extends React.Component<KitchenViewProps, KitchenViewState> {
    state: KitchenViewState = {
        kitchen: undefined,
        removable: false,
        isFavourite: undefined
    }
    render() {
        const kitchen = this.state.kitchen;
        const duties = this.state.duties;
        const groceries = this.state.groceries;
        const isFav = this.state.isFavourite;
        if (kitchen === undefined)
            this._getKitchen();
        if (!duties)
            this._getDuties();
        if (!groceries)
            this._getGroceries();
        if (isFav === undefined)
            this.isFavourite()
        return (
            <div>
                {
                    this._displayKitchen()
                }
            </div>
        );
    }
    private _display = () => {
        if (window.location.hash === displayDuties)
            return this._displayDuties()
        if (window.location.hash === displayGroceries)
            return this._displayGroceries()
        if (window.location.hash === displayAddGroceries)
            return this._displayAddItem()
        if (window.location.hash === displayAddDuty)
            return this._displayAddDuty()
        window.location.hash = displayGroceries;
    }

    private _handleAddItemButton = (_: any) => {
        window.location.hash = displayAddGroceries;
        this.setState({})
    }
    private _handleAddDutyButton = (_: any) => {
        window.location.hash = displayAddDuty;
        this.setState({})
    }
    private _handleToggleDisplay = (_: any) => {
        const display = window.location.hash;
        switch (display) {
            case displayAddDuty:
                this.setState({
                    duties: undefined
                })
            // fall through
            case displayGroceries:
                window.location.hash = displayDuties;
                break;
            case displayAddGroceries:
                this.setState({
                    groceries: undefined
                })
            // fall through
            case displayDuties:
                window.location.hash = displayGroceries;
                break;
        }
        this.setState({})
    }
    private _getDisplayButtonName = () => {
        const display = window.location.hash;
        if (display === displayGroceries) return "⇽ Display duties"
        if (display === displayAddGroceries || display === displayAddDuty) return "⇽ Back"
        return "Display groceries ⇾"
    }
    private _getKitchen = async () => {
        const id = this.props.kitchenId;
        if (id === "") this.setState({
            kitchen: null
        })
        const kitchen = await getKitchen(id);
        if (!kitchen) {
            this.setState({
                kitchen: null
            })
            return;
        }
        this.setState({
            kitchen: kitchen
        })
    }
    private _displayKitchen = () => {
        const kitchen = this.state.kitchen;
        const id = this.props.kitchenId
        const isFave = this.state.isFavourite
        if (kitchen === undefined) return (
            <div className="center mt-5">
                Getting kitchen
            </div>
        )
        if (kitchen === null) return (
            <div className="center mt-5 mb-5">{
                "Kitchen " + id + " not found!"
            }<MainView></MainView>
            </div>)
        return (
            <Col>
                <Helmet>
                    <title>{
                        kitchen!.name || "Kitjin"
                    }</title>
                </Helmet>
                <Container className="center mt-4" style={{ textAlign: "center" }}>
                    <h1>
                        {kitchen.name}
                    </h1>
                    <Button variant={isFave ? "danger" : "outline-danger"} onClick={this._handleFaveButton} className="btn fav btn-circle">{
                        isFave ? "❤️" : "🖤"
                    }</Button>
                    <div className="mt-3" style={{ fontFamily: "courier", borderWidth: 5, borderColor: "red" }}>
                        {id}
                    </div>
                    <div style={{ color: "grey", fontSize: "15px" }}>
                        {"(Share this  with your housemates!)"}
                    </div>
                    <Button onClick={this._handleToggleDisplay} className="mt-5 mb-5"> {
                        this._getDisplayButtonName()
                    } </Button>
                </Container>
                {
                    this._display()
                }
            </Col >
        )
    }
    private _displayAddDuty = () => {
        return <AddDutyView kitchen={this.state.kitchen}></AddDutyView>
    }
    private _displayAddItem = () => {
        return <AddItemView kitcen={this.state.kitchen}></AddItemView>
    }
    private _handleFaveButton = (_: any) => {
        const isFave = this.state.isFavourite;
        if (isFave === undefined) return;
        const user = Database.instance().user;
        const kitchen = this.state.kitchen
        if (!user) {
            window.alert("Please log in to add a kitchen to your favourites")
            return;
        };
        if (!kitchen) return;
        if (isFave)
            this._removeFav(user, kitchen);
        else
            this._favouriteKitchen(user, kitchen);
    }

    private _favouriteKitchen = async (user: User, kitchen: Kitchen) => {
        if (this.state.isFavourite) return;
        await addFavourite(user, kitchen);
        if (user) user.addFav(kitchen);
        this.setState({
            isFavourite: true
        })
    }
    private _removeFav = async (user: User, kitchen: Kitchen) => {
        if (this.state.isFavourite === false) return;
        await removeFavourite(user, kitchen);
        if (user) user.removeFav(kitchen)
        this.setState({
            isFavourite: false
        })
    }
    private isFavourite = async () => {
        const user = Database.instance().user;
        const kitchen = this.state.kitchen
        if (!user) return;
        if (!kitchen) return;
        const isFav = await isKitchenFavourite(user, kitchen);
        this.setState({
            isFavourite: isFav
        })
    }
    private _getGroceries = async () => {
        const kitchenId = this.props.kitchenId
        const groceries = await getGroceries(kitchenId);
        if (!groceries || kitchenId === "") {
            this.setState({
                groceries: null
            })
            return;
        }
        this.setState({
            groceries: groceries
        })
    }
    private _getDuties = async () => {
        const kid = this.props.kitchenId;
        const duties = await getDuties(kid);
        if (!duties || kid === "") {
            this.setState({
                duties: null
            })
            return
        };
        this.setState({
            duties: duties
        })

    }
    private _displayDuties = () => {
        const duties = this.state.duties;
        if (duties === undefined) return <div className="center">
            Loading duties...
        </div>
        if (duties === null) return <div className="center">
            Error getting duties
        </div>
        return <div className="center" >
            <Row style={{ textAlign: "center" }}>
                <h3>Cleaning duties</h3>
            </Row>
            <Row>
                <Button variant="outline-info" className="mt-3 mb-5" onClick={this._handleAddDutyButton}>Add duty</Button>
            </Row>
            <DutiesView kitchenId={this.props.kitchenId} duties={duties}></DutiesView>
        </div>
    }
    private _displayGroceries = () => {
        const groceries = this.state.groceries;
        if (groceries === undefined) return <div className="center">
            Loading duties...
        </div>
        if (groceries === null) return <div className="center">
            Error getting duties
        </div>
        return <div className="center">
            <Row>
                <h3>Grocery list</h3>
            </Row>
            <Row className="mt-3 mb-5">
                <Button as="span" variant="outline-danger" onClick={this._handleToggleRemovable}>{
                    this.state.removable ? "Finish removing items" : "Remove items"
                }</Button>
                <div className="mr-3 ml-3"></div>
                <Button as="span" variant="outline-success" onClick={this._handleAddItemButton}>Add item</Button>
            </Row>
            <Row >
                <GroceriesView items={groceries} removable={this.state.removable}></GroceriesView>
            </Row>
        </div >
    }
    private _handleToggleRemovable = (_: any) => {
        this.setState({
            removable: !this.state.removable
        }, () => {
            if (this.state.removable === false)
                this.setState({
                    groceries: undefined
                })
        })
    }
}

export default KitchenView;