import React from "react";
import Kitchen from "../models/kitchen";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

export interface FavouriteComponentProps {
    kitchen: Kitchen
    isPrimary: boolean;
}

export interface FavouriteComponentState {

}

class FavouriteComponent extends React.Component<FavouriteComponentProps, FavouriteComponentState> {
    render() {
        const kitchen = this.props.kitchen
        
        return (<div id={kitchen.id} className="row">
            <Link to={`/kitchen/${kitchen.id}`}>
                <Button variant="primary" className="favourite" >
                    {
                        kitchen.name
                    }
                </Button>
            </Link>
        </div>);

    }
}

export default FavouriteComponent;